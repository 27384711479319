const localization = {
  table: {
    name: "Name",
    admin: "Admin",
    population: "Population",
  },
  search: {
    label: "Search a city",
  },
};

export default localization;
